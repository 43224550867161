
import { HashRouter, Route, Router, Routes } from 'react-router-dom';
import './App.css';
import './Homepage/Homepage.css'
import './product/Product.css'
import './Form/authForm.css'
import './pages/Profile.css'
import './Orders/Order.css'
import Navbar from './Homepage/Navbar';
import Homepage from './Homepage/Homepage';
import Product from './product/Product'
import ProductDetails from './product/ProductDetails';
import Cart from './product/Cart';
import Footer from './Homepage/Footer';
import Login from './Form/Login';
import Signup from './Form/Signup';
import Forget from './Form/Forget'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PresistLogin from './pages/PresistLogin'
import RequiredLogin from './pages/Requiredlogin'
import Protected from './pages/Protected'
import ScrollToTop from './pages/ScrollToTop';
import Verify from './Form/Verify';
import Checkoutpage from './product/Checkoutpage';
import EditProfile from './pages/EditProfile';
import UserProfile from './pages/UserProfile';
import Thankyou from './product/Thankyou';
import Orders from './Orders/Orders';
import OrderDetails from './Orders/OrderDetails';
import { Category } from './product/Category';
import Contactus from './pages/Contactus'
import AboutUs from './pages/aboutus/AboutUs';
import TermsAndCondirions from './pages/TermsAndCondirions';
import PrivacyAndPolicy from './pages/PrivacyAndPolicy';
import ContactUsNew from './pages/contact/ContactUsNew';
import AboutUsNew from './pages/aboutus/AboutUsNew';
import { ChildCategory } from './product/ChildCategory';
import NavbarMobile from './Homepage/NavbarMobile';
import HandleNavbar from './Homepage/HandleNavbar';




function App() {
  return (
   <HashRouter>
<ScrollToTop />
<Routes>

<Route element={<Protected/>} >
<Route path="/Login" element ={<Login/>}/>
<Route path="/Signup" element ={<Signup/>}/>
<Route path="/Forget" element ={<Forget/>}/>
<Route path="/Verify" element ={<Verify/>}/>

</Route>


<Route element={<PresistLogin/>}>
<Route path="/" element ={<><HandleNavbar /><Homepage /><Footer /></>}/>
<Route path="/Allproduct" element ={<><HandleNavbar /><Product /><Footer /></>}/>
<Route path="/category/:id" element ={<><HandleNavbar /><Category/><Footer /></>}/>
<Route path="/child-category/:id" element ={<><HandleNavbar /><ChildCategory/><Footer /></>}/>
<Route path="/productDetails/:id" element ={<><HandleNavbar /><ProductDetails /><Footer /></>}/>


{/* <Route path="/about-us" element ={<><Navbar /><AboutUs url={"/about"}/><Footer /></>}/> */}
{/* <Route path="/about-us" element ={<><Navbar /><AboutUs/><Footer /></>}/> */}
<Route path="/about-us" element ={<><HandleNavbar /><AboutUsNew/><Footer /></>}/>
<Route path="/terms-conditions" element ={<><HandleNavbar /><TermsAndCondirions/><Footer /></>}/>
<Route path="/privacy-policy" element ={<><HandleNavbar /><PrivacyAndPolicy/><Footer /></>}/>
{/* <Route path="/contact-us" element ={<><Navbar /><Contactus/><Footer /></>}/> */}
<Route path="/contact-us" element ={<><HandleNavbar /><ContactUsNew/><Footer /></>}/>
<Route element ={<RequiredLogin/>}>
<Route path="/cart" element ={<><HandleNavbar /><Cart/><Footer /></>}/>
<Route path="/checkout" element ={<><HandleNavbar /><Checkoutpage type="cart"/><Footer /></>}/>
<Route path="/checkout-SingleProduct" element ={<><HandleNavbar /><Checkoutpage /><Footer /></>}/>
<Route path="/editprofile" element ={<><HandleNavbar /><UserProfile/><Footer /></>}/>
<Route path="/MyOrders" element ={<><HandleNavbar /><Orders/><Footer /></>}/>
<Route path="/orderDetails/:id" element ={<><HandleNavbar /><OrderDetails/><Footer /></>}/>
<Route path="/thankyou" element ={<><HandleNavbar /><Thankyou title={"Thnakyou For Choosing Us"} desc={"Payment was successful and your order is confirmed Enjoy the excellent service and fast delivery."}/><Footer /></>}/>
<Route path="/orderFailed" element ={<><HandleNavbar /><Thankyou title={"Soory Your Order Has Been Failed"} desc={"Call us Our Helpline number +917845126325 we will reach you soon"} /><Footer /></>}/>

</Route>
</Route>

</Routes>

     <ToastContainer
position="top-right"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
draggable
pauseOnHover
theme="light"
/>
</HashRouter>
  );
}

export default App;
