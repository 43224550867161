import React from 'react'
import './contact.css'

function ContactUsNew() {
  return (
    <>
      <nav className="navbar navbar-expand-md bg-dark navbar-dark">
  <a className="navbar-brand" href="#">Bean Bag Couches</a>
</nav>

<section className="contact-banner">
  <div className="container">
    <h2>Contact Us</h2>
    <p>We're here to assist you. Feel free to get in touch.</p>
  </div>
</section>

<section className="contact-info">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="contact-form">
          <h3>Send Us a Message</h3>
          <form>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email" />
            <textarea placeholder="Your Message"></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
      <div className="col-md-6">
        <img src="images/women.jpg" alt="Contact Image" />
      </div>
    </div>
  </div>
</section>

{/* <section className="contact-map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d[Map Coordinates]" frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</section> */}
    </>
  )
}

export default ContactUsNew