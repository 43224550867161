import React from 'react'

function PrivacyAndPolicy() {
  return (
    <>
   {/* <nav class="navbar navbar-expand-md bg-dark navbar-dark">
  <a class="navbar-brand" href="#">Bean Bag Couches</a>
</nav> */}

<div className="container mt-4">
  <h1 className='section-heading text-center text-md-start '>Privacy Policy</h1>
  
  <p className='normal-text'>Your privacy is important to us. It is [Company Name]'s policy to respect your privacy regarding any information we may collect from you across our website, [Website URL], and other sites we own and operate.</p>

  <h2 className='section-subheading text-center text-md-start '>Information We Collect</h2>
  <p className='normal-text text-center text-md-start '>We may collect personal information such as your name, email address, mailing address, and phone number when you place an order or contact us through our website.</p>

  <h2 className='section-subheading text-center text-md-start '>How We Use Your Information</h2>
  <p className='normal-text text-center text-md-start '>We use the information we collect to process orders, provide customer support, and send you promotional information about our products and services. We may also use your information for internal purposes such as data analysis and improving our website.</p>

  <h2 className='section-subheading text-center text-md-start '>Information Sharing</h2>
  <p className='normal-text text-center text-md-start '>We do not sell, trade, or otherwise transfer your personal information to third parties. We may share your information with trusted third parties who assist us in operating our website and conducting our business, as long as those parties agree to keep this information confidential.</p>

  <h2 className='section-subheading text-center text-md-start '>Cookies</h2>
  <p className='normal-text text-center text-md-start '>Our website uses cookies to enhance your browsing experience. You can choose to disable cookies through your browser settings, but this may affect the functionality of our website.</p>

  <h2 className='section-subheading text-center text-md-start '>Your Consent</h2>
  <p className='normal-text text-center text-md-start '>By using our website, you consent to our privacy policy.</p>

  <h2 className='section-subheading text-center text-md-start '>Contact Us</h2>
  <p className='normal-text text-center text-md-start '>If you have any questions about our privacy policy, you can contact us at .</p>

  {/* <p>[Company Name]<br>
  [Company Address]<br>
  [City, State, Zip]<br>
  [Phone Number]<br>
  [Email Address]</p> */}
</div>
</>
  )
}

export default PrivacyAndPolicy