import React from 'react'
import './aboutusnew.css';
//import about1 from '../../../public/images/abouus1.jpg'
//import about2 from '../../../public/images/aboutus2.jpg'
function AboutUsNew() {
  return (
    <>
        <section class="about-us-title">
        <div class="container">
            <h1>About Us</h1>
        </div>
    </section>

    <section class="about-company">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>About Our Company</h2>
                    <p className='simple-text'>Discover COUCHETTE, your ultimate destination for bean bag couch services. We specialize in creating comfort and style, offering a range of innovative bean bag couches that redefine relaxation. Our commitment to quality craftsmanship and design excellence ensures your spaces are transformed into cozy havens. With a passion for comfort and creativity, COUCHETTE brings you a new way to unwind, making every moment at home a delightful experience."</p>
                </div>
                <div class="col-md-6">
                    <img src="images/aboutus1.jpg" alt="Company Image" class="img-fluid" />
                </div>
            </div>
        </div>
    </section>

    <section class="how-we-work">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img src="images/aboutus2.jpg" alt="How We Work" class="img-fluid" />
                </div>
                <div class="col-md-6">
                    <h2>How We Work</h2>
                    <p className='simple-text'>Experience the magic of COUCHETTE's seamless process. Step into a world where comfort meets convenience. Our journey begins with understanding your preferences, allowing us to curate personalized bean bag couch solutions that complement your style and space. From selecting premium materials to crafting each piece with care, our artisans bring your visions to life. Next, we bring the comfort to you, ensuring hassle-free delivery and setup. We take pride in our commitment to sustainability, using eco-friendly materials and processes that prioritize both your well-being and the planet. With COUCHETTE, you're not just getting a bean bag couch; you're embracing a lifestyle of comfort, aesthetics, and thoughtfulness, where every detail is designed to enhance your relaxation experience."</p>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

export default AboutUsNew