import React from 'react'
import NavbarMobile from './NavbarMobile'
import Navbar from './Navbar'
function HandleNavbar() {
  return (
    <>
<div className="handlenav m-0 p-0 d-none d-md-block d-lg-block">
    <Navbar/>
</div>
<div className="handlenav d-block d-md-none">
    <NavbarMobile/>
</div>

    </>
     
  )
}

export default HandleNavbar