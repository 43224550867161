import React ,{useContext} from 'react'

import Banner from './Banner'
import Aboutus from './Aboutus'
import CategoryBox from './CategoryBox'
import Collection from './Collection'
import DiscoverNow from './DiscoverNow'
import History from './History'
import Footerbox from './Footerbox'
import Footer from './Footer'
import { AuthContext } from '../AuthProvider'



const Homepage = () => {
  const {CategoryData,All_Product_Page, allData} = useContext(AuthContext)
//console.log("all.....",All_Product_Page);

   const filterdata = allData.filter((val)=>val.is_recommanded===1)
         
   

console.log("alllll",allData);
    var discover1={
        title:"Peep Into" ,
        desc:"Discover all the new products and design furniture from our Catalog.",
        sub_green:"THE CATEGORIES",
        sub_text:"2023"
      }
      var discover2={
        title:"IN STOCK" ,
        desc:"Discover the selection of thousands of products Ready for Shipping at Discounted Prices",
        sub_green:"READY",
        sub_text:"TOP PRODUCTS"
      }
      
      var history1={
        title:"HISTORY OF A PASSION",
        sub_green:"EXPANSION",
        sub_text:"IN FULL SWING"
      }
      var history2={
        title:"INTERIOR DESIGN SERVICE",
        sub_green:"NO CHALLENGE",
        sub_text:"IS TOO DIFFICULT FOR US"
      }

      var Aboutus1={
        title:"ABOUT US" ,
        desc:"Welcome to COUCHETTE! We specialize in crafting unique bean bag couches that redefine comfort and style. With a passion for innovation and quality, we offer a diverse range of bean bag couches designed to suit various preferences and spaces. Our mission is to provide the ultimate lounging experience, combining modern aesthetics with unparalleled coziness. Explore our collection and discover the perfect bean bag couch to transform your relaxation moments.",
        sub_green:"Couchette",
        sub_text:" LIVING HOME"
      }
      var Aboutus2={
        title:"LIGITING" ,
        desc:"At Couchette, we simplify comfort. Explore our range of bean bag couches, select your style, and place an order. Our team ensures top-notch craftsmanship and timely delivery. From fabric selection to stitching, every detail is handled with care. We prioritize your comfort, offering a variety of designs to suit your space. Experience relaxation reinvented with Couchette – where coziness meets convenience.",
        sub_green:"PROJECTING ",
        sub_text:"AND CONSULTING"
      }

 console.log("all pro..", All_Product_Page);
  return (
    <>
    <div className="mobile-banner mt-5 mt-md-0">
    <Banner />
    </div>

<DiscoverNow content={discover1} dataArr={CategoryData} Url="/Allproduct" />
<History content={history1}/>
<Collection />
<DiscoverNow content={discover2} dataArr={filterdata} type={"product"} row={"row"} Url="/category/16" />
{/* <Aboutus content={Aboutus2} row={"row-reverse"}/> */}

<div className="d-md-block d-none"><br/></div>
<DiscoverNow content={discover2} dataArr={ filterdata.length>0?(filterdata?.slice(5, 10)):(filterdata)} type={"product"} row={"row-reverse"} Url="/category/16"  />
{/* <History content={history2}/> */}
<div className="d-md-block d-none"><br/></div>
<CategoryBox />
{/* <Aboutus content={Aboutus1} row={"row"}/> */}
{/* <Footerbox /> */}


    </>
  )
}
 
export default Homepage