import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <div className="" style={{background:'rgb(63, 63, 63)'}}>
<div  className="Footer section-padding ">

<div>
    <p className="section-subheading">Office</p>

    <a className="normal-text link-a"><span className="Footer-icon"><i class="bi bi-house-door-fill "></i></span>Couchette Décor Private Limited
357-B, Sector 37, Pace City II,
Gurgaon, Haryana-122001</a>

    {/* <a className="normal-text link-a"><span className="Footer-icon" ><i class="bi bi-house-door-fill "></i></span>P.IVA:</a>

    <a className="normal-text link-a"><span className="Footer-icon"><i class="bi bi-telephone-fill "></i></span>(+91)
</a>

    <a className="normal-text link-a"><span className="Footer-icon"><i class="bi bi-envelope-fill "></i></span>
    </a> */}


{/* ----icon --------------- */}
<div style={{display:'flex',gridGap:10}}>
<a href='https://www.instagram.com/couchette_official/#' className="social-link"><i class="bi bi-instagram"></i></a>
<a className="social-link"><i class="bi bi-twitter "></i></a>
<a href='https://www.facebook.com/couchette.in/' className="social-link"><i class="bi bi-facebook "></i></a>
<a className="social-link"><i class="bi bi-whatsapp "></i></a>
{/* <a className="social-link"><i class="bi bi-linkedin "></i></a> */}
</div>
</div>

<div>
    <p className="section-subheading">Quick Link </p>

    <Link to="/about-us" className="normal-text link-a">About Us</Link>
    <a className="normal-text link-a">Our Story</a>
    <Link to="/editprofile" className="normal-text link-a">My Account </Link>
    <Link to="/Allproduct" className="normal-text link-a">Product </Link>

   
</div>
<div>
    <p className="section-subheading">Contact Us  </p>

    <Link to="/contact-us" className="normal-text link-a">Contact Us </Link>
    <a href="tel:555-555-5555" className="normal-text link-a">Call us </a>
    <a href = "mailto: abc@example.com" className="normal-text link-a">Mail us </a>
   
  

   
</div>
<div>
    <p className="section-subheading">Information</p>

    <Link to='/privacy-policy' className="normal-text link-a">Privacy & policy </Link>
    <Link to="/terms-conditions" className="normal-text link-a">Terms & Condition </Link>
    {/* <a className="normal-text link-a">Terms & Condition </a>
    <a className="normal-text link-a">Terms & Condition </a>
    <a className="normal-text link-a">Terms & Condition </a> */}



   
</div>
{/* <div>
    <p className="section-subheading">Payment methode </p>

    <a className="normal-text link-a">Privacy & policy </a>
    <a className="normal-text link-a">Terms & Condition </a>
    <a className="normal-text link-a">My Account </a>
    <a className="normal-text link-a">Product </a>
    <a className="normal-text link-a">Terms & Condition </a>
    <a className="normal-text link-a">My Account </a>
    <a className="normal-text link-a">Product </a>

   
</div> */}

</div>
<div className="footer-copyright-section center-div" style={{borderTop:'1px solid #4d4d4d',padding:'1rem'}}>
    <p className='normal-text' style={{color: 'white'}}>Copyright © 2023 - All rights reserved. Powered by <a style={{color:'white'}} href="https://techninza.in/">Techninza</a></p>
</div>
  </div>
  )
}

export default Footer