import React from 'react'

function TermsAndCondirions() {
  return (
    <>
   {/* <nav class="navbar navbar-expand-md bg-dark navbar-dark">
  <a class="navbar-brand" href="#">Bean Bag Couches</a>
</nav> */}

<div class="container mt-4">
  <h1 className='section-heading text-center'>Terms and Conditions</h1>

  
  <h2 className='section-subheading text-center text-md-start '>1. Acceptance of Terms</h2>
  <p className='normal-text'>By accessing or using our website and services, you agree to these terms and conditions. If you do not agree, please do not use our services.</p>

  <h2 className='section-subheading text-center text-md-start '>2. Service Description</h2>
  <p className='normal-text'>Our company provides bean bag couches and related services. The description, pricing, and availability of our products and services are subject to change without notice.</p>

  <h2 className='section-subheading text-center text-md-start '>3. Orders and Payment</h2>
  <p className='normal-text'>When you place an order, you agree to provide accurate and complete information. Payment is required at the time of purchase. We accept [Payment Methods].</p>

  <h2 className='section-subheading text-center text-md-start '>4. Shipping and Delivery</h2>
  <p className='normal-text'>We strive to deliver products within the estimated timeframe, but delays may occur. Shipping costs and delivery options are outlined on our website.</p>

  <h2 className='section-subheading text-center text-md-start '>5. Returns and Refunds</h2>
  <p className='normal-text'>We have a [Return and Refund Policy] in place. Please review it for information on returning products and requesting refunds.</p>

  <h2 className='section-subheading text-center text-md-start '>6. Intellectual Property</h2>
  <p className='normal-text'>All content on our website, including images and text, is protected by copyright and other intellectual property laws. You may not use our content without permission.</p>

  <h2 className='section-subheading text-center text-md-start '>7. Limitation of Liability</h2>
  <p className='normal-text'>We are not liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising from the use of our products or services.</p>

  <h2 className='section-subheading text-center text-md-start '>8. Governing Law</h2>
  <p className='normal-text'>These terms and conditions are governed by the laws of [Jurisdiction]. Any disputes shall be resolved in the courts of [Jurisdiction].</p>

  <h2 className='section-subheading text-center text-md-start '>9. Contact Us</h2>
  <p className='normal-text'>If you have any questions about our terms and conditions, you can contact us at [Contact Email Address].</p>






  {/* <p>[Company Name]<br>
  [Company Address]<br>
  [City, State, Zip]<br>
  [Phone Number]<br>
  [Email Address]</p> */}
</div>
</>
  )
}

export default TermsAndCondirions