import React,{useContext,useState,useEffect} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {AuthContext} from '../AuthProvider'
import Logout from '../Form/Logout'
import './Homepage.css'

function NavbarMobile() {

  const location = useLocation()
  const logout = Logout();
  const {userToken,userData,CategoryData,All_Product_Page,cart} = useContext(AuthContext)
  const navigate = useNavigate();
//console.log("category.....", CategoryData);
const [SearchArr,setSearchArr] = useState([])
const [searchShow,setSearchShow] = useState(false)

const handleSearch=(inputvalue)=>{
 
  setSearchShow(true)
  inputvalue = inputvalue.toLowerCase()
  var PaintingArr = All_Product_Page?.filter((element=>{
    var lowercase = element.name.toLowerCase().split(" ").join("");
    
    
    return lowercase.includes(inputvalue) || element.name.toLowerCase().includes(inputvalue) 
  }))
//console.log(PaintingArr)
  
setSearchArr([...PaintingArr])
}

const debounce = (func, delay) => {
  let timer
  return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
          func(...args)  // func.apply(null,args)     
      }, delay)
  }
}

useEffect(() => {

  setSearchShow(false)
},[location?.pathname])
 


const filerSearch = debounce((inputvalue) => handleSearch(inputvalue), 300)


  return (
    <>
         <nav className="navbar bg-body-tertiary fixed-top">
    <div className="container-fluid">
      <Link to="/" style={{fontWeight:900,color:'#383838'}} className="navbar-brand">Couchette</Link>
      {!userToken ?
        <Link to='/login'><i className="bi bi-person"></i></Link>
        :(<>
          <a  className="dropdown">
      
      <i className="bi bi-person dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"></i>
      
      <ul className="dropdown-menu navbardropdown">
     <li> <img style={{height:70,width:70,borderRadius:'50%',margin:'10px auto',objectFit:'cover'}} src={userData?.avatar}></img></li>
    <li><Link to="/editprofile" className="dropdown-item" href="#"> <i className="bi bi-person-lines-fill "></i> Edit Profile</Link></li>
    <li><Link to="/MyOrders" className="dropdown-item" ><i className="bi bi-bag"></i>my orders</Link></li>
    <li onClick={logout}><a className="dropdown-item" href="#"> <i className="bi bi-list" ></i>Logout</a></li>
  </ul>
      </a>
        </>)}
        <a><i className="bi bi-telephone"></i></a>
      <Link to="/cart"><i className="bi bi-cart position-relative" >
      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill " style={{backgroundColor:"#02a89e",padding:'3px 5px',fontSize:12}}>
    {cart.length ? cart.length :0}
    <span class="visually-hidden">unread messages</span>
  </span>
      </i></Link>

      <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel">COUCHETTE</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item mb-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar">
              <Link className="nav-link link-a nav-main-title" aria-current="page" to="/Allproduct">ALL PRODUCT</Link>
            </li>
            {CategoryData.map((element)=>(
              <li className="nav-item dropdown mb-2" >
               <div style={{display:'inline'}}  data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" >
               <Link to={"/category/"+element?.id}  className="nav-link nav-main-title dropdown-toggle d-inline" role="button">
              {element?.name}
              </Link>
               </div>
              
              
              {element?.sub_categories?.length ?(<>
                <a className="nav-link dropdown-toggle d-inline ms-3" href="#" role="button" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-caret-down-fill"></i>
              </a>
                {element?.sub_categories?.map((ele)=>(
                  <ul className="dropdown-menu">
              {/* submenu1 */}
                 
                <li className="dropdown-submenu">
                  <a className="dropdown-item dropdown-toggle nav-sub-menu" style={{fontWeight:700,color: 'rgb(56, 56, 56)'}} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {ele?.name} <i class="bi bi-chevron-down"></i>
                  </a>
                  {ele.child_categories.length ?(<>
                    <ul className="dropdown-menu ">
                    {ele?.child_categories?.map((subele)=>(
                      <li className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"><Link to={"/child-category/"+subele?.id} className="dropdown-item nav-title" href="#">{subele?.name}</Link></li>
                    ))}
                    
                  </ul>
                  </>):null}
                  
                </li> 
              </ul>

                ))}
              
              </>):null}
            </li>

            ))}
            
          </ul>
          
          <div className="d-flex mt-3" role="search">
            {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success" type="submit">Search</button> */}
            {/*  */}
            
      <div style={{maxWidth:'300px',width:'100%',position:'relative', display:'flex'}} >
 <input type="text" className="form-control" id="search" placeholder="Search bar "
  style={{backgroundColor: 'transparent',border: '1px solid #cccccc'}}
    onChange={(e)=>filerSearch(e.target.value)}
    
    autocomplete="off"
  />
    {searchShow && (  <div className="searchdropdown" >
        {SearchArr.length ?  SearchArr?.map((element)=>{
  
    return <div onClick={()=>navigate("/productDetails/" + element.product_id)}>
      <img src={element.images.length ? element.images[0]:null}></img>
      <p>{element.name}</p>
      <p>&#x20B9; {element?.price}</p>
    </div>}):<div>no product found</div>}
    </div>)}
  </div>
 
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  </nav>
    
    </>
  )
}

export default NavbarMobile