import React from 'react'
import {AuthContext} from '../AuthProvider'
import collection1 from '../asets/img/collection1.png'
import collection2 from '../asets/img/collection2.png'
import collection3 from '../asets/img/collection3.png'
import collection4 from '../asets/img/collection4.png'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
const Collection = () => {
  const {CategoryData} = useContext(AuthContext)
     // console.log("cat.....",CategoryData)
  return (
    <div className="section-padding">
    <div className="collection ">
    <div className="item-1">
    <Link className='coll-img' to={"/child-category/"+CategoryData[0]?.sub_categories[0]?.child_categories[1]?.id}>
       <div>
       <img src={collection1} alt='img' className='img-fluid' />
       </div>
     
      <h3 className='collectionName'>BELLISIMO</h3>
      </Link>
      
    </div>
    <div className="item-2 ">
    <Link className='coll-img' to={"/child-category/"+CategoryData[0]?.sub_categories[0]?.child_categories[9]?.id}>
    <div>
    <img src={collection2} className='img-fluid' alt='img' />
    </div>
   
    <h3 className='collectionName'>FRIO</h3>
    </Link>
    
    </div>
    <div className="item-3">
    <Link className='coll-img' to={"/child-category/"+CategoryData[0]?.sub_categories[0]?.child_categories[0]?.id}>
   <div>
   <img src={collection3} alt='img' className='img-fluid' />
   </div>
   
    <h3 className='collectionName'>HUGE-E</h3>
    </Link>
    </div>
    <div className="item-4">
    <Link className='coll-img' to={"/child-category/"+CategoryData[0]?.sub_categories[0]?.child_categories[17]?.id}>
   <div>
   <img src={collection4} alt='img' className='img-fluid mt-1' />
   </div>
   
    <h3 className='collectionName'>POPILLON</h3>
    </Link>
    </div>

    {/* <div className="item-1">
      <img src="https://www.miliashop.com/themes/leo_cool_stuff/img/modules/appagebuilder/images/Furniture_banner_3.jpg"></img>
    </div>
    <div className="item-2">
    <img src="https://www.miliashop.com/themes/leo_cool_stuff/img/modules/appagebuilder/images/banner_lighting_top.jpg"></img>
    </div>
    <div className="item-3">
    <img src="https://www.miliashop.com/themes/leo_cool_stuff/img/modules/appagebuilder/images/Kitchen_banner_2.jpg"></img>
    </div>
    <div className="item-4">
    <img src="https://www.miliashop.com/themes/leo_cool_stuff/img/modules/appagebuilder/images/Outdoor_banner_4.jpg"></img>
    </div> */}
 
    </div> 

    </div>
  )
}

export default Collection