import React,{useEffect,useState,useContext,useRef, useMemo, useCallback} from 'react'
import History from '../Homepage/History'
import { Link,useParams,useNavigate,useLocation} from 'react-router-dom'
import { AuthContext } from '../AuthProvider'
import Toast from '../Toast'
import axios from '../axios';
import { Add_to_cart,remove_cart ,update_to_cart} from '../Function'
import Loader from '../pages/Loader'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './Product.css'
import ReactImageMagnify from 'react-image-magnify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const ProductDetails = () => {
  const navigate =useNavigate()
  const location = useLocation()
  const {cart,userToken,dispatch} = useContext(AuthContext)
  const [isLoading,setIsLoading] = useState(true);
  const {id} = useParams()
  const [productDetails, setProductDetails] = useState([]);
  const [imgstate,setImageState]=useState("")
 const [Images, setImages] = useState("")
  // mui
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // mui end

  // react image lightbox
const [photoIndex, setPhotoIndex] = useState(0);
const [isOpen, setIsOpen] = useState(false);


const photo = [productDetails.main_image]
productDetails?.images?.forEach((val)=>{
               photo.push(val)
      })


const openLightbox = () => {
  setIsOpen(true);
};

const closeLightbox = () => {
  setIsOpen(false);
};

const movePrevRequest = () => {
  setPhotoIndex((photoIndex + photo.length - 1) % photo.length);
};

const moveNextRequest = () => {
  setPhotoIndex((photoIndex + 1) % photo.length);
};

// image light end
  const get_details = async (url,type) => {
    
    try {
      const response = await axios({
        method: "get",
        url: url,
      });

      if (response.status === 200) {
        const data = response?.data;
        if(type=="product"){
          setProductDetails(data?.product)
          setImageState(data?.product?.images?.length &&(data?.product?.images[0]) )
          
        }
        // else{
        //   setHomepage((p)=>({...p,["Recommended"]:data?.products}))
        // }
       

        //   Toast(data.message,response.status)
      }
    } catch (err) {
      const error = err?.response?.data;
      Toast(error?.message);
    }
    finally{
      setIsLoading(false);
    }
  };


  useEffect(()=>{
    get_details(`/get_product?product_id=${id}`,'product')
    // !Recommended?.length? get_details('/get_recommanded_products'):setIsLoading(false)
    
  },[id])

   //console.log("details", productDetails);

  const cart_section =()=>{
    
    var cartdata =cart
    var arr =[]
    var data = cartdata?.find((element)=>{return element?.product?.product_id == productDetails?.product_id})
    
    if(data==undefined ){
       arr.push( 
       <div  style={{display:'flex',margin:'1rem 0 ',gridGap:20}}>

       <div className="cart-update between-div ">
       <i class="bi bi-dash"></i>
       <p>0</p>
       <i class="bi bi-plus"  onClick={(e)=>{e.preventDefault();Add_to_cart(productDetails,navigate,location,userToken,dispatch)}}></i>
       </div>
   
   
   {/* icon section wishlist and cart  */}
       <div className='center-div cart-icon' style={{width: '50px', height: '50px',border:' 1px solid #4747472d'}} >
   <i class="bi bi-heart"></i>
   </div>
   
       <div className='center-div cart-icon' style={{width: '50px', height: '50px',border:' 1px solid #4747472d'}} onClick={(e)=>{e.preventDefault();Add_to_cart(productDetails,navigate,location,userToken,dispatch)}}>
   <i class="bi bi-cart"></i>
   </div>
   
   
       </div>

       )
    }else{
      arr.push( 
        <div  style={{display:'flex',margin:'1rem 0 ',gridGap:20}}>
 
        <div className="cart-update between-div ">
        <i class="bi bi-dash" onClick={()=>update_to_cart(productDetails?.product_id,-1,userToken,dispatch)}></i>
        <p>{data?.qty}</p>
        <i class="bi bi-plus" onClick={()=>update_to_cart(productDetails?.product_id,1,userToken,dispatch)}></i>
        </div>
    
    
    {/* icon section wishlist and cart  */}
        <div className='center-div cart-icon' style={{width: '50px', height: '50px',border:' 1px solid #4747472d'}} >
    <i class="bi bi-heart"></i>
    </div>
    
        <div className='center-div cart-icon' style={{width: '50px', height: '50px',border:' 1px solid #4747472d'}} onClick={(e)=>{e.preventDefault();remove_cart(productDetails,userToken,dispatch)}}>
    <i class="bi bi-cart-fill"></i>
    </div>
    
    
        </div>
 
        )
    }

    return arr

  }

  const ratingCalculate = useCallback((star)=>{
    //console.log(star)
    var total = productDetails?.reviews?.reduce((a,b)=>{
      if(b.rating==star){

        return a+Number(b?.rating)
      }
    },0)

   // console.log(total)
    
   
  },[])
  
  const getstar =(rating) => {
    var total = 5
    var star =[]
    for(var i=0; i<Math.floor(rating);i++){
        star.push(<div 
            style={{clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', 
            background:"#fed001",
            height:15,
            width:15
            
            }}>

            </div>)
    }
    for(var i=0; i<total-rating;i++){
        star.push(<div 
            style={{clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', 
            background:'#D9D1D1',
            height:15,
            width:15
            
            }}>

            </div>)
    }
    return star
}

  const carthtml = useMemo(cart_section,[cart,id,productDetails])



// 


  return (
    <>
      {isLoading && (<Loader />)}    
      <div className="product-details-top mt-5 mt-md-0" style={{padding:'1.5rem',background:'rgba(226, 226, 226, 0.3)'}}>

      <p className="normal-text " style={{marginBottom:'2rem'}}><i class="bi bi-house-door-fill" ></i> &nbsp; /  &nbsp; All product &nbsp; / &nbsp; <span style={{color:'#02a89e'}}>{id}</span></p>
      </div>

      {/*------------------- product details start  ----------------------*/}
      <div className="product-details-container  max-width-2000px  mx-auto ">
       <div className="row">
            <div className="col-12">
            {isOpen && (
        <Lightbox
          mainSrc={photo[photoIndex]}
          nextSrc={photo[(photoIndex + 1) % photo.length]}
          prevSrc={photo[(photoIndex + photo.length - 1) % photo.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={movePrevRequest}
          onMoveNextRequest={moveNextRequest}
        />
      )}
            </div>
  <div className="col-12 col-md-6 product-details-left">
     <div className="d-none d-md-block">
  <button type="button" onClick={openLightbox}>
       
     
    <div style={{width:'400px', height:'400px'}} className='magnify-container'>
{Images? (<>
 
  
                    <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: Images,
                        },
                        largeImage: {
                            src: Images,
                            width: 1800,
                           height: 1200
                        },
                        enlargedImageContainerDimensions: {
                                width: '270%',
                                height: '120%',                           
                            },
                       
                        shouldUsePositiveSpaceLens: true
                    }} />
    
</>):(
  <>
  <ReactImageMagnify {...{
    smallImage: {
        alt: 'Wristwatch by Ted Baker London',
        isFluidWidth: true,      
        src: productDetails?.main_image,
        
    },
    largeImage: {
        src: productDetails?.main_image,      
        width: 1800,
        height: 1200
    },
    enlargedImageContainerDimensions: {
                                 width: '270%',
                                height: '120%',  
                            },
                            shouldUsePositiveSpaceLens: true,
            isHintEnabled: true,
           
 
}} />

  </>
)}
</div>
 </button>
</div>
       <button type="button" className="d-block d-md-none" onClick={openLightbox}>
         {Images?<img src={Images} className='zoom-image' style={{margin:'0 auto',width:'60%',maxWidth:'400px'}} alt="img" />:<img className='zoom-image' src={productDetails?.main_image} style={{margin:'0 auto',width:'60%',maxWidth:'400px'}} alt='img' />} 
</button>
          <div  className='d-flex flex-md-row flex-wrap'>
          <img src={productDetails?.main_image} onClick={()=>setImages(productDetails?.main_image)}
    style={{cursor:'pointer', width:60,margin:'0.5rem 1rem',boxShadow:' rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}
   loading="lazy" className='zoom-image' alt="img"/>
          {productDetails?.images?.map((element)=>{

            return     <img src={element} onClick={()=>setImages(element)}
    style={{cursor:'pointer', width:60,margin:'0.5rem 1rem',boxShadow:' rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}
   loading="lazy" className='zoom-image' alt="img"/>
          })}
  </div>
        </div>
        <div className="col-12 col-md-6  product-details-right">

        <h2 className="" style={{textAlign:'left'}}> {productDetails?.name}</h2>
       {productDetails?.sub_title?<h2 className="" style={{textAlign:'left'}}> {productDetails?.sub_title}</h2>:null} 
        <p className="" style={{fontSize:20,color:'grey'}}>&#x20B9; {productDetails?.price}</p>
        {/* add item */}
{carthtml}
{/* add item end */}
        <button className="themeButton" style={{width:'100%'}} onClick={()=>navigate('/checkout-SingleProduct' ,{state:{data:[{product:productDetails}]}} )}>BUY NOW</button>
        <h5 className='normal-text'><b>Description :</b></h5>
        <p className='product-box-desc normal-text'style={{height:'200px',overflow: 'auto',padding:'0 1rem'}} dangerouslySetInnerHTML={{__html: productDetails?.description }}></p>

        <p className="dark-text" >{productDetails?.short_description}</p>

        <div >
    <span>Available in 8/9 weeks</span>

{/* cart buttton section  */}
{/* add item 
{carthtml}
{/* add item end */}
   

    {/* cart update end  */}

    {/* <button className="themeButton" style={{width:'100%'}} onClick={()=>navigate('/checkout-SingleProduct' ,{state:{data:[{product:productDetails}]}} )}>BUY NOW</button> */}


    {/* rating section excellence  */}
    <div className="star-section center-div" style={{gridGap:'10px',padding:10}}>
      <h1 className="section-subheading" style={{margin:0}} > Excellence </h1>
      <div className=" center-div" style={{gridGap:'5px'}}>
          {getstar(productDetails?.reviews?.reduce((a,b)=>a+Number(b?.rating),0)/productDetails?.reviews?.length)}
      </div>
      <span  style={{fontSize:14}}>{productDetails?.reviews?.length} Review on </span>
      <span style={{fontSize:14,fontWeight:700}}>Trust Pilot </span>
    </div>

    {/* excellence end  */}


        </div>
        </div>
      </div>
      </div>
      {/* ----------------product details end -------------- */}

     
      {/* <p className="normal-text" style={{marginBottom:'2rem',textAlign:'center'}}>Description &nbsp; /  &nbsp; Review </p> */}
      <div className='product-details-bottom d-flex section-paddingX container' style={{gridGap:30,padding:'1rem 0',flexWrap:'wrap-reverse'}}>

    <div className='product-details-bottom-left ' style={{flex:1}}>
    {productDetails?.reviews?.length ? 
    productDetails?.reviews?.map((element)=>{

      return  <div className='product-details-review'>
        <img src={element?.avatar}></img>
        
        <div>
        <p style={{fontSize:16,fontWeight:600,marginBottom:6}}>{element?.customer_name}</p>
        <div style={{display:'flex',gridGap:5,marginBottom:6}}>{getstar(Number(element?.rating))}</div>
        <p style={{fontSize:12}}>{element?.review}</p>
        </div>
      </div>
    })
    :<div className='product-details-review'>
        
                {/* <p style={{fontSize:16,fontWeight:600,marginBottom:6}}>no Review Found</p> */}
                {/* details */}
                <Box className="normal-text" sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}  aria-label="lab API tabs example"
          sx={{overflowX:'scroll'}}
          >
            <Tab label="Color" value="1" sx={{fontSize:'12px'}} />
            <Tab label="Description" value="2" sx={{fontSize:'12px'}} />
            <Tab label="Care" value="3" sx={{fontSize:'12px'}} />
            <Tab label="Material" value="4" sx={{fontSize:'12px'}} />
            <Tab label="Dimensions" value="5" sx={{fontSize:'12px'}} />
          </TabList>
        </Box>
        <TabPanel className='normal-text' value="1">{productDetails?.color}</TabPanel>
        <TabPanel value="2"><p className='normal-text' dangerouslySetInnerHTML={{__html: productDetails?.description }}></p></TabPanel>
        <TabPanel className='normal-text' value="3">{productDetails?.care}</TabPanel>
        <TabPanel className='normal-text' value="4">{productDetails?.material}</TabPanel>
        <TabPanel className='normal-text' value="5">{productDetails?.dimensions}</TabPanel>
      </TabContext>
    </Box>
</div>


    }
    
     
    </div>
    <div className='product-details-bottom-right' style={{flex:0.4}}>
      <h6>What About to say</h6>
      <div>
    <p style={{fontSize:12,fontWeight:500,color:'#939393'}}>{productDetails?.reviews?.length} Review | {productDetails?.reviews?.reduce((a,b)=>a+Number(b?.rating),0)} rating</p>
    {[...Array(5)]?.map((element,index)=>{

      return  <div className="d-flex align-items-center" style={{gridGap:10,fontSize:13}}>
      {index+1}
      <div 
            style={{clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', 
            background:"#fed001",
            height:15,
            width:15
            
            }}>

            </div>

      <div style={{ height: 5, width: 160, backgroundColor: '#C4C4C4', margin: '0px 5px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', borderRadius: 5, }}>
      <div style={{width:(productDetails?.reviews?.filter((a)=> Number(a.rating) == index+1 )?.length/(productDetails?.reviews?.length))*20,height:5, borderRadius: 5,backgroundColor: index == 3 ? '#EC8F5A' : index == 4 ? '#F55936' : '#34A853',}}></div>
       
    </div>
    {productDetails?.reviews?.filter((a)=> Number(a.rating) == index+1 ).length} rating
    </div>
    })}
   


      </div>
    </div>
      </div>
      
    </>
  )
}

export default ProductDetails